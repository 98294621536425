import React from "react";
import PropTypes from "prop-types";

class DebounceInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = { value: null };
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    onChange(event) {
        const { delay } = this.props;
        const { value } = event.target;

        this.setState({ value });

        clearTimeout(this.callbackDelay);

        this.callbackDelay = setTimeout(async () => {
            const currentValue = this.state.value;

            this.props.onChange(currentValue);

            this.setStateSafely({ value: null });
        }, delay);
    }

    get value() {
        const { value } = this.state;
        return value !== null ? value : this.props.value;
    }

    setStateSafely(state) {
        if (!this.mounted) return;
        this.setState(state);
    }

    render() {
        const props = { ...this.props };

        delete props.value;
        delete props.onChange;

        return <input value={this.value} onChange={this.onChange} {...props} />;
    }
}

DebounceInput.defaultProps = {
    delay: 300,
};

DebounceInput.propTypes = {
    delay: PropTypes.number,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default DebounceInput;
