import React from "react";
import { createRoot } from "react-dom/client";

import { initAxios } from "~/shared/utils";

import RegionSelect from "./RegionSelect";

initAxios();

const container = document.getElementById("home-region-select");
const root = createRoot(container);

root.render(<RegionSelect />);
