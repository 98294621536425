import React from "react";
import PropTypes from "prop-types";
import styles from "./Spinner.less";

class Spinner extends React.Component {
    static renderDefault() {
        return (
            <div className={styles.bounce}>
                <div className={styles.bounceChildren} />
                <div
                    className={`${styles.bounceChildren} ${styles.bounceAnimation}`}
                />
            </div>
        );
    }

    static renderDots() {
        return (
            <div className={styles.dots}>
                <div
                    className={`${styles.dotsChildren} ${styles.dotsChild1}`}
                />
                <div
                    className={`${styles.dotsChildren} ${styles.dotsChild2}`}
                />
                <div className={styles.dotsChildren} />
            </div>
        );
    }

    static renderSmallDots() {
        return (
            <div className={`${styles.dots} ${styles.smallDots}`}>
                <div
                    className={`${styles.dotsChildren} ${styles.dotsChild1}`}
                />
                <div
                    className={`${styles.dotsChildren} ${styles.dotsChild2}`}
                />
                <div className={styles.dotsChildren} />
            </div>
        );
    }

    renderLogo() {
        return (
            <div className="u-text-center">
                <video playsInline autoPlay loop muted width="200px">
                    <source
                        src={
                            this.props.isDesktopApp
                                ? "../../media/img/swimcloud/logo/logo-animation.mp4"
                                : "/media/img/swimcloud/logo/logo-animation.mp4"
                        }
                        type="video/mp4"
                    />
                </video>
            </div>
        );
    }

    render() {
        if (this.props.dots) return Spinner.renderDots();
        if (this.props.smallDots) return Spinner.renderSmallDots();
        if (this.props.logo) return this.renderLogo();

        return Spinner.renderDefault();
    }
}

Spinner.defaultProps = {
    dots: false,
    smallDots: false,
    logo: false,
    isDesktopApp: false,
};

Spinner.propTypes = {
    dots: PropTypes.bool,
    smallDots: PropTypes.bool,
    logo: PropTypes.bool,
    isDesktopApp: PropTypes.bool,
};

export default Spinner;
