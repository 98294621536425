import {
    filterByName,
    fetchRegionTree,
    flattenRegionTree,
    getRegionBreadcrumbs,
} from "~/shared/utils";

class RegionApi {
    static regionTree = null;

    static flatChoices = [];

    static getRegionByChoiceValue(choiceValue) {
        return RegionApi.flatChoices.find(
            (choice) => choice.choice_value === choiceValue,
        );
    }

    static async getRegionTree() {
        if (!RegionApi.regionTree) {
            const tree = await fetchRegionTree({
                includeGlobal: true,
                compact: true,
            });
            RegionApi.regionTree = tree;
            RegionApi.flatChoices = flattenRegionTree(tree);
        }
        return RegionApi.regionTree;
    }

    static getRegionChainedName(region) {
        const breadcrumbs = getRegionBreadcrumbs({ region });
        const parts = breadcrumbs.map((option) => {
            const { display_name, abbr } = option;
            return `${display_name} ${abbr}`;
        });
        return parts.join(" ");
    }

    static getSearchResults(searchText) {
        const { flatChoices } = RegionApi;
        return filterByName(searchText, flatChoices, (option) =>
            RegionApi.getRegionChainedName(option),
        );
    }
}

export default RegionApi;
