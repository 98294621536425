import React from "react";

import { WORLDWIDE_REGION } from "~/shared/constants";

import RegionModal from "./RegionModal";

import styles from "./RegionSelect.less";

class RegionSelect extends React.Component {
    constructor(props) {
        super(props);
        const { dataset } = document.getElementById("home-region-select") || {};
        const { regionName, regionChoiceValue, regionLogoUrl } = dataset || {};
        this.state = {
            regionName: regionName || WORLDWIDE_REGION.display_name,
            regionChoiceValue:
                regionChoiceValue || WORLDWIDE_REGION.choice_value,
            regionLogoUrl: regionLogoUrl || WORLDWIDE_REGION.logo_url,
            showModal: false,
        };
        this.onOpenModal = this.onOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
    }

    onOpenModal() {
        this.setState({ showModal: true });
    }

    onCloseModal() {
        this.setState({ showModal: false });
    }

    renderModal() {
        const { showModal, regionChoiceValue } = this.state;
        return (
            showModal && (
                <RegionModal
                    currentChoiceValue={regionChoiceValue}
                    onClose={this.onCloseModal}
                />
            )
        );
    }

    render() {
        const { regionName, regionLogoUrl } = this.state;
        return (
            <div className="p-home-section__header">
                <h2 className="p-home-section__title">
                    <img
                        className="c-avatar c-avatar--tiny u-object-contain"
                        src={regionLogoUrl}
                        width="38"
                        height="38"
                        alt={`${regionName} logo`}
                    />
                    <button
                        className={`btn ${styles.button} js-gtm-global-region-button`}
                        onClick={this.onOpenModal}
                        type="button"
                        aria-label={regionName}
                    >
                        <span className={styles.text}>{regionName}</span>
                        <i className="far fa-angle-down u-text-default" />
                    </button>
                </h2>
                {this.renderModal()}
            </div>
        );
    }
}

export default RegionSelect;
