import React from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import { Modal } from "react-bootstrap";

import Spinner from "~/shared/components/Spinner";
import DebounceInput from "~/shared/components/DebounceInput";
import { getRegionBreadcrumbs } from "~/shared/utils";

import RegionApi from "./RegionApi";
import RegionItem from "./RegionItem";

import styles from "./RegionModal.less";

class RegionModal extends React.Component {
    static scrollToActiveItem() {
        const el = document.querySelector(`.${styles.active}`);
        if (el) el.scrollIntoView({ block: "center" });
    }

    static scrollToTop() {
        const el = document.querySelector(".o-list-bare");
        if (el) el.scrollIntoView();
    }

    constructor(props) {
        super(props);
        this.state = {
            searchText: "",
            regionTree: null,
            currentRegion: null,
            saving: false,
        };
        this.onSave = this.onSave.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.onItemClick = this.onItemClick.bind(this);
    }

    async componentDidMount() {
        this.mounted = true;
        const regionTree = await RegionApi.getRegionTree();
        const { currentChoiceValue } = this.props;
        const currentRegion =
            RegionApi.getRegionByChoiceValue(currentChoiceValue);
        await this.setStateSafely({ regionTree, currentRegion });
        RegionModal.scrollToActiveItem();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    onSearchChange(value) {
        this.setState({ searchText: value });
        RegionModal.scrollToTop();
    }

    onItemClick(option) {
        this.setState({
            currentRegion: option,
        });
    }

    onSave() {
        this.setState({ saving: true });
        const { currentRegion } = this.state;
        const { choice_value } = currentRegion;
        const stringified = queryString.stringify({
            r: choice_value,
        });
        window.location.href = `/?${stringified}`;
    }

    setStateSafely(state) {
        if (!this.mounted) return;
        this.setState(state);
    }

    renderSearchResults() {
        const { searchText, currentRegion } = this.state;
        const filtered = RegionApi.getSearchResults(searchText);

        if (!filtered.length)
            return (
                <div className={styles.empty}>
                    There are no regions matching
                    <strong>&nbsp;{searchText}</strong>
                </div>
            );

        const items = filtered.map((option) => {
            const { choice_value: choiceValue } = option;
            return (
                <li key={choiceValue}>
                    <RegionItem
                        option={option}
                        currentRegion={currentRegion}
                        onClick={this.onItemClick}
                        layout="flat"
                    />
                </li>
            );
        });

        return <ul className="o-list-bare">{items}</ul>;
    }

    renderRegionTree() {
        const { regionTree, searchText, currentRegion } = this.state;
        if (!regionTree) return <Spinner />;
        if (searchText) return this.renderSearchResults();

        const expandedRegions = getRegionBreadcrumbs({
            region: currentRegion,
            param: "choice_value",
        }).filter((x) => x !== currentRegion.choice_value);

        const _renderRegionTree = (regions) => {
            const items = regions.map((region) => {
                const { choice_value, subregions: _subregions } = region;
                const subregions = _subregions || [];
                const defaultExpanded = expandedRegions.includes(choice_value);
                return (
                    <li key={choice_value}>
                        <RegionItem
                            option={region}
                            currentRegion={currentRegion}
                            onClick={this.onItemClick}
                            layout="nested"
                            defaultExpanded={defaultExpanded}
                        >
                            {subregions.length ? (
                                <div className={styles.nestedList}>
                                    {_renderRegionTree(subregions)}
                                </div>
                            ) : null}
                        </RegionItem>
                    </li>
                );
            });
            return <ul className="o-list-bare">{items}</ul>;
        };

        return _renderRegionTree(regionTree);
    }

    renderInput() {
        const { searchText } = this.state;
        return (
            <DebounceInput
                type="text"
                className="form-control u-mt++"
                placeholder="Search..."
                autoFocus
                value={searchText}
                onChange={this.onSearchChange}
            />
        );
    }

    render() {
        const { currentRegion, saving } = this.state;
        return (
            <Modal
                show
                onHide={this.props.onClose}
                animation={false}
                restoreFocus={false}
                dialogClassName="modal-scrollable"
            >
                <Modal.Header>
                    <Modal.Title>Select your region</Modal.Title>
                    {this.renderInput()}
                </Modal.Header>
                <Modal.Body>{this.renderRegionTree()}</Modal.Body>
                <Modal.Footer>
                    <button
                        onClick={this.props.onClose}
                        type="button"
                        className="btn btn-default"
                    >
                        Cancel
                    </button>
                    {currentRegion && (
                        <button
                            onClick={this.onSave}
                            type="button"
                            className="btn btn-primary"
                        >
                            {saving && (
                                <i
                                    className="fad fa-spinner-third fa-spin u-mr"
                                    aria-hidden="true"
                                />
                            )}
                            Save
                        </button>
                    )}
                </Modal.Footer>
            </Modal>
        );
    }
}

RegionModal.propTypes = {
    currentChoiceValue: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default RegionModal;
